const SPACE_DATA = {
  "u6983831":  [{space_js: "//zhfpbdmym.wzzhwlkj.com/production/ql/common/kt/openjs/n-dn-du.js", space_src: "baidujs", space_type: "inters", space_id: "u6983831"}],
  "u6983832":  [{space_js: "//zhfpbdmym.wzzhwlkj.com/site/r_mlu_oeoe_b.js", space_src: "baidujs", space_type: "native", space_id: "u6983832"}],
  "u6983833":  [{space_js: "//zhfpbdmym.wzzhwlkj.com/source/snm-vp/source/f/openjs/pf-f.js", space_src: "baidujs", space_type: "native", space_id: "u6983833"}],
  "u6983834":  [{space_js: "//zhfpbdmym.wzzhwlkj.com/production/t/source/o/resource/nwq/g/qgk.js", space_src: "baidujs", space_type: "native", space_id: "u6983834"}],
  "u6983835":  [{space_js: "//zhfpbdmym.wzzhwlkj.com/production/upo/production/x-rh-r/static/hm.js", space_src: "baidujs", space_type: "native", space_id: "u6983835"}],
};
export const SPACE_MAP = {
HomeInter:  SPACE_DATA["u6983831"],
HomeBanner:  SPACE_DATA["u6983832"],
HomeNative:  SPACE_DATA["u6983833"],
HomeNativeCenter1:  SPACE_DATA["u6983834"],
HomeNativeCenter2:  SPACE_DATA["u6983835"],
RecipeDetailBanner:  SPACE_DATA["u6983832"],
RecipeDetailNative:  SPACE_DATA["u6983833"],
TopicDetailBanner:  SPACE_DATA["u6983832"],
TopicDetailNative:  SPACE_DATA["u6983833"],
BaikeDetailBanner:  SPACE_DATA["u6983832"],
BaikeDetailNative:  SPACE_DATA["u6983833"],
SolarDetailBanner:  SPACE_DATA["u6983832"],
ResultInter:  SPACE_DATA["u6983831"],
AnswerInter:  SPACE_DATA["u6983831"],
ListInter:  SPACE_DATA["u6983831"],
ListBanner:  SPACE_DATA["u6983832"],
ListNative:  SPACE_DATA["u6983833"],
ListNativeCenter1:  SPACE_DATA["u6983834"],
ListNativeCenter2:  SPACE_DATA["u6983835"],
}

export const foodsolarList = [
  {
    id: "1",
    color: "background: linear-gradient(180deg, rgba(0, 163, 129, 0) 0%, #00A381 10%);",
    primary: "#00A381",
    solar_term: "立春"
  },
  {
    id: "2",
    color: "background: linear-gradient(180deg, rgba(106, 139, 172, 0) 3%, #698AAB 10%);",
    primary: "#698AAB",
    solar_term: "雨水"
  },
  {
    id: "3",
    color: "background: linear-gradient(180deg, rgba(113, 166, 183, 0) 3%, #71A6B7 11%);",
    primary: "#71A6B7",
    solar_term: "惊蛰"
  },
  {
    id: "4",
    color: "background: linear-gradient(180deg, rgba(134, 52, 49, 0) 3%, #853330 11%);",
    primary: "#853330",
    solar_term: "春分"
  },
  {
    id: "5",
    color: "background: linear-gradient(180deg, rgba(134, 52, 49, 0) 3%, #853330 11%);",
    primary: "#853330",
    solar_term: "清明"
  },
  {
    id: "6",
    color: "background: linear-gradient(180deg, rgba(81, 101, 37, 0) 3%, #506525 11%);",
    primary: "#506525",
    solar_term: "谷雨"
  },
  {
    id: "7",
    color: "background: linear-gradient(180deg, rgba(0, 163, 129, 0) 0%, #00A381 10%);",
    primary: "#00A381",
    solar_term: "立夏"
  },
  {
    id: "8",
    color: "background: linear-gradient(180deg, rgba(81, 101, 37, 0) 3%, #506525 11%);",
    primary: "#506525",
    solar_term: "小满"
  },
  {
    id: "9",
    color: "background: linear-gradient(180deg, rgba(81, 101, 37, 0) 3%, #506525 11%);",
    primary: "#506525",
    solar_term: "芒种"
  },
  {
    id: "10",
    color: "background: linear-gradient(180deg, rgba(71, 131, 132, 0) 3%, #478384 11%);",
    primary: "#478384",
    solar_term: "夏至"
  },
  {
    id: "11",
    color: "background: linear-gradient(180deg, rgba(71, 131, 132, 0) 3%, #478384 11%);",
    primary: "#478384",
    solar_term: "小暑"
  },
  {
    id: "12",
    color: "background: linear-gradient(180deg, rgba(134, 52, 49, 0) 3%, #853330 11%);",
    primary: "#853330",
    solar_term: "大暑"
  },
  {
    id: "13",
    color: "background: linear-gradient(180deg, rgba(176, 147, 59, 0) 3%, #AF923A 11%);",
    primary: "#AF923A",
    solar_term: "立秋"
  },
  {
    id: "14",
    color: "background: linear-gradient(180deg, rgba(176, 147, 59, 0) 3%, #AF923A 11%);",
    primary: "#AF923A",
    solar_term: "处暑"
  },
  {
    id: "15",
    color: "background: linear-gradient(180deg, rgba(186, 141, 71, 0) 3%, #B98C46 11%);",
    primary: "#B98C46",
    solar_term: "白露"
  },
  {
    id: "16",
    color: "background: linear-gradient(180deg, rgba(186, 141, 71, 0) 3%, #B98C46 11%);",
    primary: "#B98C46",
    solar_term: "秋分"
  },
  {
    id: "17",
    color: "background: linear-gradient(180deg, rgba(186, 141, 71, 0) 3%, #B98C46 11%);",
    primary: "#B98C46",
    solar_term: "寒露"
  },
  {
    id: "18",
    color: "background: linear-gradient(180deg, rgba(166, 165, 196, 0) 3%, #A6A5C4 11%);",
    primary: "#A6A5C4",
    solar_term: "霜降"
  },
  {
    id: "19",
    color: "background: linear-gradient(180deg, rgba(129, 40, 55, 0) 3%, #812937 11%);",
    primary: "#812937",
    solar_term: "立冬"
  },
  {
    id: "20",
    color: "background: linear-gradient(180deg, rgba(166, 165, 196, 0) 3%, #A6A5C4 11%);",
    primary: "#A6A5C4",
    solar_term: "小雪"
  },
  {
    id: "21",
    color: "background: linear-gradient(180deg, rgba(166, 165, 196, 0) 3%, #A6A5C4 11%);",
    primary: "#A6A5C4",
    solar_term: "大雪"
  },
  {
    id: "22",
    color: "background: linear-gradient(180deg, rgba(158, 177, 194, 0) 3%, #9DB0C1 11%);",
    primary: "#9DB0C1",
    solar_term: "冬至"
  },
  {
    id: "23",
    color: "background: linear-gradient(180deg, rgba(158, 177, 194, 0) 3%, #9DB0C1 11%);",
    primary: "#9DB0C1",
    solar_term: "小寒"
  },
  {
    id: "24",
    color: "background: linear-gradient(180deg, rgba(158, 177, 194, 0) 3%, #9DB0C1 11%);",
    primary: "#9DB0C1",
    solar_term: "大寒"
  }
];
